.assign-reason-modal-overlay-fade{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .assign-reason-modal-content-section {
    position: relative;
    transform: translate(-0%, 0%);
    background-color: #f2f2f2;
    /* padding: 20px 0px; */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(4, 4, 4, 0.2);
    min-width: 600px;
    height: 250px;
    max-width: 600px;
    box-sizing: border-box;
  }


  .assign-reason-modal-content-header{
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 18px 20px 10px;
    font-size: 18px;
    font-weight: 600;
  }

  .assign-reason-modal-content-header p{
    margin: 0;
  }

 
  .assign-reason-modal-content-body {
    display: flex;
    justify-content: start;
    align-items: center;
    /* padding: 30px */
  }

  .assign-search-textarea{
    outline: none;
    border: none;
    /* background-color: #f2f2f2; */
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    padding: 10px;
    margin: 15px;
    border: 1px solid #ccd1d5;
    border-radius: 5px;
    /* margin-top: 15px; */
  }

  .assign-reason-textarea {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
  }
  
  .assign-reason-actions-btn {
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    border-radius: 8px;
    background: #f2f2f2;
    right: 0;
    padding: 10px 20px
  }
  
  .assign-reason-btn-save {
    background-color: #4999c9;
    border: none;
    padding: 10px 20px;
    color: #fff;
    border-radius: 10px;
  }
  .assign-reason-btn-cancel {
    background-color: #6c757d;
    border: none;
    padding: 10px 13px;
    color: #fff;
    border-radius: 10px;
    margin-right: 10px;
  }
  
  /* .users-assign-loading-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .users-assign-loading-section p {
  } */